import React, { useState } from "react";
import { Layout, Button, Form, Input } from "antd";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { supabase } from "../components/supaClient";
import logo from "../assets/images/verifymagically.png";
import { toast } from "react-toastify";
import GoogleButton from "../components/GoogleButton/GoogleButton";

const { Header, Content } = Layout;

export default function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const affiliateValue = queryParams.get('aff'); 

  const signUp = async (values) => {
    try {
      setIsLoading(true);
      const { email, password } = values;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        setIsLoading(false);
        return toast.error("Please enter a valid email");
      }
      const response = await fetch(
        "https://api.verifymagically.com/webhook/user-exists",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );
      const data = await response.json();
      if (data.user_found) {
        return toast.error(
          "User already Exists with this email. Please Login or Reset Password"
        );
      }
      const { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${process.env.REACT_APP_BASE_URL}/login`,
          data: {
            affiliate_id:affiliateValue
          }
        },
      });

      if (error) {
        toast.error("Could not register account");
        return;
      }
      toast.success("Sign up successful, please check your email to verify");
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      console.log(error);
      toast.error("Failed to sign up");
    } finally {
      setIsLoading(false);
    }
  };

  const signUpWithGoogle = async () => {
    try {
      setIsLoading(true);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider:'google',
        options: {
          redirectTo: `${process.env.REACT_APP_BASE_URL}/login`,
        },
      })
      
      console.log(data)
      if (error) {
        toast.error("Could not register account");
        return;
      }

      setTimeout(() => {
        setIsLoading(false)
      }, 5000);
    } catch (error) {
      console.log(error);
      toast.error("Failed to sign up");
    } finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    (async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        const { data: user } = await supabase.from("users").select("*");
        
        if (user.length === 0) {
          return navigate("/?type=newuser")
        } else{
          return navigate("/");
        }
      }
    })();
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    toast.error("Failed to sign up");
  };

  return (
    <>
      <Layout className="">
        <Header>
          <span className="flex justify-start items-center font-semibold text-white">
            <img src={logo} width="50px" alt={"Magically Genius"} />
            Verify Magically
          </span>
        </Header>
        <Content className="h-screen flex items-start justify-center pt-36">
          <div className="w-[450px]">
            <h1 className="text-4xl font-semibold">Register</h1>
            <br />
            <p className="font-normal text-muted" level={5}>
              Enter your email and password to sign up
            </p>
            <br />
            <Form
              onFinish={signUp}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="w-full"
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                className="w-full"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" disabled={isLoading} />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="text-white border-none bg-[#3cd9be] w-full h-[40px] font-medium"
                  loading={isLoading}
                >
                  SIGN UP
                </Button>
              </Form.Item>
              <Form.Item>
              <GoogleButton
                label="Sign up with Google"
                onClick={() => signUpWithGoogle()}
              />
              </Form.Item>
              <p className="font-semibold text-muted">
                Already have an account?{" "}
                <Link to="/login" className="text-dark font-bold">
                  Sign In
                </Link>
              </p>
            </Form>
          </div>
        </Content>
      </Layout>
    </>
  );
}
