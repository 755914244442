import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'G-X4N9KC1XFB'
};

export const initializeGTM = () => {
  TagManager.initialize(tagManagerArgs);
  console.log(TagManager)
};
