/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Modal, Row, Select, Typography } from "antd";
import { FileContext } from "../context/fileContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 300,
    width: 300,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

export default function CSVReader() {
  const navigate = useNavigate();
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const { fileName, fileData, setFileName, setFileData, user, acceptedFile } =
    useContext(FileContext);
  const [submitting, setSubmitting] = useState(false);
  const [fieldType, setFieldType] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  // const handleDropdownChange = (id, value) => {
  //   // If the selected value is 'email', ensure only this field has 'email' type
  //   console.log(id, value);

  //   if (fieldType.length === 0) {
  //     // Initialize the fieldType array with the first field
  //     setFieldType([{ id, type: value }]);
  //     return;
  //   } else {
  //     if (value === "email") {
  //       let oldIndex = false;

  //       const updatedFields = fieldType.map((field) => {
  //         if (field.id === id) {
  //           // This is the selected field, update its type
  //           return { ...field, type: value };
  //           oldIndex = true;
  //         } else if (value === "email" && field.type === "email") {
  //           // Reset any other field that was previously set to 'email'
  //           // to a default type, e.g., 'text'
  //           return { ...field, type: "" };
  //         }
  //         return field;
  //       });
  //       if (!oldIndex) {
  //         // Add the new field to the fieldType array
  //         setFieldType([...updatedFields, { id, type: value }]);
  //         return;
  //       }
  //     }
  //   }
  // };

  const selectMenu = [
    {
      value: "",
      label: "Choose",
      disabled: true,
    },
    {
      value: "email",
      label: "Email",
    },
    // {
    //   value: "text",
    //   label: "Text",
    // },
    // {
    //   value: "number",
    //   label: "Number",
    // },
  ];

  const handleDropdownChange = (id, value) => {
    if (value === "email") {
      const hasEmailAlready = fieldType.filter(
        (field) => field.type === "email"
      );
      if (hasEmailAlready?.length > 0) {
        const updatedFields = fieldType.map((field) => {
          if (field.id === hasEmailAlready[0]?.id) {
            return { ...field, type: "" };
          }
          if (field.id === id) {
            return { ...field, type: "email" };
          }
          return field;
        });
        setFieldType(updatedFields);
        return;
      }
    }
    const updatedFields = fieldType.map((field) => {
      if (field.id === id) {
        return { ...field, type: value };
      }
      return field;
    });
    setFieldType(updatedFields);
  };

  useEffect(() => {
    if (fileData.length > 0) {
      const fields = fileData[0].map((val, index) => {
        return { id: index, type: "" };
      });
      setFieldType(fields);
    }
  }, [fileData]);

  const handleCheck = async () => {
    setSubmitting(true);
    try {
      const formData = new FormData();
      if (fieldType.findIndex((field) => field.type === "email") !== -1) {
        formData.append("file", acceptedFile);
        formData.append("user-email", user?.email);
        formData.append("user-unique-key", user?.uniqueUserID);
        formData.append(
          "email-header-index",
          fieldType.findIndex((field) => field.type === "email")
        );
        const response = await fetch(
          `https://upload-file-verifymagically.darsh-parsana587.workers.dev/`,
          {
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin":"*",
              Authorization: `Basic ${process.env.REACT_APP_NOBOUNCE_AUTHORIZATION}`,
            },
            body: formData,
          }
        );
        const uploadFileResponse = await response.json();
        if (response.status === 200) {
          navigate("/");
        } else {
          toast.error(
            uploadFileResponse?.message ?? "Error while submitting the file"
          );
        }
      } else {
        toast.error("Please select an email field");
      }
    } catch (err) {
      let error = err?.message;
      if(error.includes("Not Enough")){
        error = "Not Enough Credits!";
      }
      
      toast.error(error ?? "Error while submitting the file");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {!fileUploaded && (
        <div className="py-20 w-full h-full flex flex-col items-center">
          <CSVReader
            onUploadAccepted={(results) => {
              setFileData(results.data);
              setZoneHover(false);
              setFileUploaded(true);
            }}
            onDragOver={(event) => {
              event.preventDefault();
              setZoneHover(true);
            }}
            onDragLeave={(event) => {
              event.preventDefault();
              setZoneHover(false);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
              Remove,
            }) => (
              <>
                <div
                  {...getRootProps()}
                  className="cursor-pointer min-h-[250px] min-w-[250px] max-h-[500px] max-w-[500px] flex items-center justify-center"
                >
                  {acceptedFile ? (
                    <>
                      {setFileName(acceptedFile?.name)}
                      <div style={styles.file}>
                        <div style={styles.info}>
                          <span className="text-xs bg-transparent">
                            {formatFileSize(acceptedFile.size)}
                          </span>
                          <span className="text-xs bg-transparent">
                            {acceptedFile.name}
                          </span>
                        </div>
                        <div style={styles.progressBar}>
                          <ProgressBar />
                        </div>
                        <div
                          {...getRemoveFileProps()}
                          style={styles.remove}
                          onMouseOver={(event) => {
                            event.preventDefault();
                            setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                          }}
                          onMouseOut={(event) => {
                            event.preventDefault();
                            setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                          }}
                        >
                          <Remove color={removeHoverColor} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Card
                      className="flex items-center justify-center w-full h-full flex-col rounded-2xl bg-white"
                      title={
                        <span className="flex flex-col items-center justify-center p-8 gap-14">
                          <h1 className="font-bold text-gray-700 text-2xl">
                            Upload CSV File
                          </h1>
                          <span className="text-6xl text-[#6e58f0]">
                            <CloudUploadOutlined />
                          </span>
                          <div className="text-sm font-semibold text-gray-700 text-center">
                            Select a CSV file to import <br />
                            or
                            <br />
                            Drag & Drop CSV file here
                          </div>
                        </span>
                      }
                    >
                      <span className="text-gray-700 text-sm">
                        Upload you CSV files to import leads
                      </span>
                    </Card>
                  )}
                </div>
              </>
            )}
          </CSVReader>
        </div>
      )}
      <div>
        {fileData.length > 0 && (
          <>
            <Flex
              justify="space-between"
              items="center"
              className="p-3 bg-white rounded-md shadow-md"
            >
              <Typography.Title level={5}>
                Upload File: {fileName}
              </Typography.Title>
              <div className="flex flex-row flex-wrap gap-3">
                <button
                  className="mx-2 px-7 py-2 rounded-md border border-green-600 hover:bg-green-600 hover:text-white font-semibold"
                  onClick={() => setShowPreview(true)}
                >
                  Preview
                </button>
                <button
                  className="mx-2 px-7 py-2 rounded-md border border-orange-600 hover:bg-orange-600 hover:text-white font-semibold"
                  onClick={() => {
                    setFileUploaded(false);
                    setFileData([]);
                  }}
                >
                  Re-upload
                </button>
                <button
                  className="mx-2 px-7 py-2 rounded-md border border-red-600 hover:bg-red-600 hover:text-white font-semibold"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
              </div>
            </Flex>
            <Modal
              title={`Previewing ${fileName}`}
              centered
              open={showPreview}
              onCancel={() => setShowPreview(false)}
              width={"80%"}
              footer={[
                <Button key="cancel" onClick={() => setShowPreview(false)}>
                  Cancel
                </Button>,
              ]}
            >
              <div className="overflow-x-scroll">
                <table>
                  <thead>
                    {fileData[0].map((val, index) => (
                      <th
                        key={index}
                        className="pr-12 p-2 min-w-[150px] border border-gray-400 uppercase"
                      >
                        {val}
                      </th>
                    ))}
                  </thead>
                  <tbody>
                    {fileData.slice(1, 11).map((val, index) => (
                      <tr key={index} className="border border-gray-400">
                        {val.map((ele, index) => (
                          <td className="pr-12 p-2 min-w-[150px]" key={index}>
                            {ele}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
                {fileData.length > 11 && (
                  <span className="text-lg text-blue-800 font-medium">
                    {`Additional ${fileData.length - 11} rows to parse`}
                  </span>
                )}
              </div>
            </Modal>
            <Typography.Title level={3} className="mt-4">
              Select Field Type
            </Typography.Title>

            <div className="bg-white p-4 mt-1 rounded-md shadow-md">
              {fileData[0].map((val, index) => (
                <Row justify={"start"} key={index} className="py-1">
                  <Col span={6} className="capitalize font-bold">
                    {val}
                  </Col>
                  <Col span={2}></Col>
                  <Col span={6}>
                    <Select
                      key={index}
                      value={fieldType[index]?.type}
                      placeholder={"Choose"}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => {
                        handleDropdownChange(index, e);
                      }}
                      options={selectMenu}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                className="mt-4 w-1/4 border border-blue-500 bg-blue-500 text-white"
                onClick={handleCheck}
                loading={submitting}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
