import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const PaymentFailed = () => (
  <Result
    status="error"
    title="Order Request Failed"
    subTitle="Please check and try placing your request again."
    extra={[
      <Link to="/" key="home">
        <Button>Go Home</Button>
      </Link>,
      <Link to="/billing" key="buy">
        <Button className="border border-blue-500 bg-blue-500 text-white">
          Buy Again
        </Button>
      </Link>,
    ]}
  />
);
export default PaymentFailed;
