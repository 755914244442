import ReactApexChart from "react-apexcharts";
import { Dropdown, Flex, Popconfirm, Spin } from "antd";
import { useContext, useState } from "react";
import {
  MoreOutlined,
  FallOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  // ContainerOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { FileContext } from "../../context/fileContext";

function DonutChart({ data }) {
  const { user } = useContext(FileContext);
  const [open, setOpen] = useState(false);

  const handleMenuClick = (e) => {
    if (e.key === "3") {
      setOpen(false);
    }
  };
  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const handleDeleteFile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NOBOUNCE_WEBHOOK_URL}/delete-file`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${process.env.REACT_APP_NOBOUNCE_AUTHORIZATION}`,
          },
          body: JSON.stringify({
            id: data?.id,
            "user email": user?.email,
          }),
        }
      );
      const responseData = await response.json();
      if (response.status === 200) {
        return toast.success(responseData.message);
      }
    } catch (err) {
      return toast.error(err.message);
    }
  };

  const donutChart = {
    series: [
      ((data.valid_emails / data.total_emails) * 100).toFixed(2),
      ((data.invalid_emails / data.total_emails) * 100).toFixed(2),
      ((data.other_emails / data.total_emails) * 100).toFixed(2),
    ],
    options: {
      labels: ["Valid Emails", "Invalid Emails", "Other"],
      colors: ["#198754", "#dc3545", "#f59e0b"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "26px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              color: "black",
              formatter: function () {
                return data.total_emails;
              },
            },
          },
        },
      },
    },
  };

  const items = [
    {
      label: (
        <a
          href={`${data.original_download_url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Original File
        </a>
      ),
      key: "original_file",
    },
    {
      label: (
        <a
          href={`${data.valid_emails_download_url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Valid Emails
        </a>
      ),
      key: "valid_emails",
    },
    {
      label: (
        <a
          href={`${data.invalid_emails_download_url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Invalid Emails
        </a>
      ),
      key: "invalid_emails",
    },
    {
      label: (
        <a
          href={`${data.other_emails_download_url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Unverifiable Emails
        </a>
      ),
      key: "other_emails",
    },
    {
      label: (
        <Popconfirm
          title="Delete File"
          description="Are you sure to delete this file? This action cannot be undone."
          onConfirm={handleDeleteFile}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <div>Delete</div>
        </Popconfirm>
      ),
      key: "delete_file",
    },
  ];

  const handleCardByStatus = (status) => {
    switch (status) {
      case "Completed":
        return (
          <div>
            <div className="linechart">
              <Flex
                justify={"space-between"}
                horizontal="true"
                style={{ width: "100%" }}
              >
                <span className="truncate font-semibold text-lg" title={data?.filename}>
                  {data?.filename}
                </span>
                <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  onOpenChange={handleOpenChange}
                  open={open}
                >
                  <span
                    onClick={(e) => e.preventDefault()}
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  >
                    <MoreOutlined />
                  </span>
                </Dropdown>
              </Flex>
            </div>

            <ReactApexChart
              options={donutChart.options}
              series={donutChart.series}
              type="radialBar"
              height={300}
              width={"100%"}
            />
            <br />
            <Flex gap="middle" justify={"space-between"} horizontal="true">
              {/* <Flex vertical align={"center"} gap={0}>
              <span style={{ color: "#abc123", fontSize: "22px" }}>
                <ContainerOutlined />
              </span>
              <div style={{ fontSize: "32px", fontWeight: "bold" }}>
                {data.total_emails}
              </div>
              <div className="text-[#444]">Total Emails</div>
            </Flex> */}
              <Flex vertical={true} align={"center"} gap={0}>
                <a
                  href={`${data.valid_emails_download_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ color: "#198754", fontSize: "22px" }}>
                    <CheckCircleOutlined />
                  </span>
                  <div
                    style={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {data.valid_emails}
                  </div>
                </a>
                <div className="text-[#444] text-center">Valid Emails</div>
              </Flex>
              <Flex vertical={true} align={"center"} gap={0}>
                <a
                  href={`${data.invalid_emails_download_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ color: "#dc3545", fontSize: "22px" }}>
                    <CloseCircleOutlined />
                  </span>
                  <div
                    style={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {data.invalid_emails}
                  </div>
                </a>
                <div className="text-[#444] text-center">Invalid Emails</div>
              </Flex>
              <Flex vertical={true} align={"center"} gap={0}>
                <a
                  href={`${data.other_emails_download_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ color: "#f59e0b", fontSize: "22px" }}>
                    <FallOutlined />
                  </span>
                  <div
                    style={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {data.other_emails}
                  </div>
                </a>
                <div className="text-[#444] text-center">
                  Unverifiable Emails
                </div>
              </Flex>
            </Flex>
          </div>
        );
      case "Processing":
        return (
          <Flex
            align="center"
            justify="center"
            gap="middle"
            vertical={true}
            style={{ width: "100%", minHeight: "400px" }}
          >
            <div className="flex items-center justify-center flex-col w-full h-full gap-20">
              <Spin size="large" className="blue-spin" />
              <h1 className="text-[#345abc] text-xl">Validating...</h1>
            </div>
          </Flex>
        );
      case "Failed":
        return (
          <div className="w-full min-h-[400px]">
            <div className="linechart w-full">
              <div className="truncate font-semibold text-lg">
                {data?.filename}
              </div>
            </div>

            <div className="text-red-600 text-5xl flex flex-col items-center gap-6 mt-32">
              <ExclamationCircleOutlined />
              Failed
            </div>
          </div>
        );
      default:
        return (
          <Flex
            align="center"
            justify="center"
            gap="middle"
            vertical={true}
            style={{ width: "100%", minHeight: "400px" }}
          >
            <div className="flex items-center justify-center flex-col w-full h-full gap-20">
              <Spin size="large" className="yellow-spin" />
              <h1 className="text-[#f59e0b] text-xl">Pending...</h1>
            </div>
          </Flex>
        );
    }
  };

  return <div>{handleCardByStatus(data?.status)}</div>;
}

export default DonutChart;
