import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const PaymentLoading = () => (
  <Result
    status="404"
    title="Processing Your Order"
    extra={[
      <Link to="/" key="home">
        <Button className="border border-blue-500 bg-blue-500 text-white">
          Go Home
        </Button>
      </Link>,
    ]}
  />
);
export default PaymentLoading;
