export const pushToDataLayer = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  };
  
  // Example functions for various events
  export const trackPurchase = (purchaseData) => {  
    pushToDataLayer({
      event: 'purchase_credits',
      purchase_success: {
        currency: purchaseData.currency,
        credits: purchaseData.credits,
        user_email: purchaseData.user_email,
        name: purchaseData.customer_name
      }
    });
  };
  
  export const trackNewSignup = (userData) => {
    console.log("New User Event Fire",userData);
    pushToDataLayer({
      event: 'new_user_signup',
      new_user: {
        credits:userData.credits,
        email:userData.email,
        uniqueUserID:userData.uniqueUserID, 
        uniqueAPI:userData.uniqueAPI,
        stripeID:userData.stripeID
      }
    });
  };
  
  // Define other tracking functions similarly
  






//   import React from 'react';
// import { trackPurchase } from './analytics';

// const CheckoutComponent = ({ purchaseData }) => {
//   const handlePurchase = () => {
//     // Logic to handle purchase

//     // Track purchase event
//     trackPurchase(purchaseData);
//   };

//   return (
//     <button onClick={handlePurchase}>Complete Purchase</button>
//   );
// };

// export default CheckoutComponent;
