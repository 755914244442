import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PaymentSuccess from "../components/PaymentSuccess";
import PaymentLoading from "../components/PaymentLoading";
import PaymentFailed from "../components/PaymentFailed";
import { supabase } from "../components/supaClient";
import { trackPurchase } from '../components/custompixel';

const PaymentConfirmation = () => {
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const { type, chargeid } = useParams();

  useEffect(() => {
    const fetchInvoice = async () => {
      if (chargeid !== undefined) {
        try {
          const body = { charge: chargeid };
          const response = await fetch(
            process.env.REACT_APP_N8N_URL + "check-for-invoice",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );

          if (response.status !== 200) {
            throw new Error("Something went wrong");
          }

          const dataFromApi = await response.json();
          
          if (dataFromApi.fetch_success === true) {
            setIsSuccess(type === "success");

            const { data: invoice } = await supabase
              .from("Invoice")
              .select("*")
              .eq("charge_id", chargeid)
              .single();

            if(invoice==null){

              const purhcaseData = {
                currency: dataFromApi['item'].currency,
                credits: dataFromApi['item']['metadata']['credits'],
                user_email: dataFromApi['item']['metadata']['user_email'],
                name: dataFromApi['item']['customer_details']['customer_name']
              };

              await trackPurchase(purhcaseData);

              const { data, error } = await supabase
              .from('Invoice')
              .insert([
                { charge_id: chargeid },
              ])
              .select().single();

              if(error || data===null){
                throw error;
              }

            }
          } else {
            setIsSuccess(false);
          }
        } catch (error) {
          console.error(error);
          setIsSuccess(false);
        }
      } else {
        setIsSuccess(false);
      }
      setLoading(false);
    };

    fetchInvoice();
  }, [chargeid, type]);

  if (loading) {
    return <PaymentLoading />;
  }

  return isSuccess ? <PaymentSuccess /> : <PaymentFailed />;
};

export default PaymentConfirmation;
