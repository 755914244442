import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Button, Form, Input, Modal } from "antd";
import { supabase } from "../components/supaClient";
import logo from "../assets/images/verifymagically.png";
import { toast } from "react-toastify";
import GoogleButton from "../components/GoogleButton/GoogleButton";
const { Header, Content } = Layout;

export default function SignIn() {
  const navigate = useNavigate();
  const [resetEmail, setResetEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);

  const handleUpdatePassword = async () => {
    try {
      setIsEmailSending(true);
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(resetEmail)) {
        return toast.error("Please enter a valid email");
      }
      if (resetEmail.length === 0) {
        return toast.error("Please enter your email");
      }
      const response = await fetch(
        "https://api.verifymagically.com/webhook/user-exists",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: resetEmail }),
        }
      );
      const data = await response.json();
      if (!data.user_found) {
        return toast.error(
          "User doesn't exist with this email. Please Register"
        );
      }
      const { error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
        redirectTo: `${process.env.REACT_APP_BASE_URL}/reset-password`,
      });

      if (error) {
        return toast.error(error?.message ?? "Could not update password");
      }
      return toast.success("Email for Reset Password sent sucessfully");
    } catch (error) {
      return toast.error(error?.message ?? "Failed to send email");
    } finally {
      setIsEmailSending(false);
    }
  };

  const signIn = async (values) => {
    try {
      setIsLoading(true);
      const { email, password } = values;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        return toast.error("Please enter a valid email");
      }
      if (email.length === 0) {
        return toast.error("Please enter your email");
      }
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        return toast.error(error.message);
      }
      const { data: user } = await supabase.from("users").select("*");
        
      if (user.length === 0) {
        return navigate("/?type=newuser")
      } else{
        return navigate("/");
      }
      
    } catch (error) {
      console.log(error);
      toast.error("Failed to sign in");
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    toast.error("Failed to sign in");
  };
  const signInWithGoogle = async () => {
    try {
      setIsLoading(true);

      const { error } = await supabase.auth.signInWithOAuth({
        provider:'google',
        options: {
          redirectTo: `${process.env.REACT_APP_BASE_URL}/login`,
        },
      })
      

      if (error) {
        toast.error("Could not register account");
        return;
      }

      setTimeout(() => {
        setIsLoading(false)
      }, 5000);
    } catch (error) {
      console.log(error);
      toast.error("Failed to sign up");
    } finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    (async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        const { data: user } = await supabase.from("users").select("*");

        if (user.length === 0) {
          navigate("/?type=newuser")
        } else{
          navigate("/");
        }
      }
    })();
  }, []);

  return (
    <>
      <Modal
        title="Reset Password"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button key="back" onClick={() => setOpenModal(false)}>
            Close
          </Button>,
          <Button
            key="submit"
            onClick={handleUpdatePassword}
            loading={isEmailSending}
          >
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label="Email"
            onChange={(e) => setResetEmail(e.target.value)}
            autoComplete="false"
            disabled={isLoading}
            value={resetEmail}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Layout className="">
        <Header>
          <span className="flex justify-start items-center font-semibold text-white">
            <img src={logo} width="50px" alt={"Magically Genius"} />
            Verify Magically
          </span>
        </Header>
        <Content className="h-screen flex items-start justify-center pt-36">
          <div className="w-[450px]">
            <h1 className="text-4xl font-semibold">Login</h1>
            <br />
            <p className="font-normal text-muted" level={5}>
              Enter your email and password to sign in
            </p>
            <br />
            <Form
              onFinish={signIn}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="w-full"
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" autoComplete="false" />
              </Form.Item>

              <Form.Item
                className="w-full"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  autoComplete="false"
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={isLoading}
                  className="text-white border-none bg-[#3cd9be] w-full h-[40px] font-medium"
                >
                  SIGN IN
                </Button>
              </Form.Item>
              <Form.Item>
              <GoogleButton
                onClick={() => signInWithGoogle()}
              />
              </Form.Item>
              <p className="font-semibold text-muted">
                Having Trouble Signing In{" "}
                <span
                  className="text-red-500 cursor-pointer hover:underline"
                  onClick={() => setOpenModal(true)}
                >
                  Forgot Password?
                </span>
              </p>
              <br />
              <p className="font-semibold text-muted">
                Don't have an account?{" "}
                <Link to="/register" className="text-dark font-bold">
                  Sign Up
                </Link>
              </p>
            </Form>
          </div>
        </Content>
      </Layout>
    </>
  );
}
