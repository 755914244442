import { Card, Input, Button, Flex } from "antd";
import { supabase } from "../components/supaClient";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { FileContext } from "../context/fileContext";
import { CopyOutlined } from "@ant-design/icons";

function Profile() {
  const { user, setUser } = useContext(FileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpdatePassword = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.resetPasswordForEmail(user?.email, {
        redirectTo: `${process.env.REACT_APP_BASE_URL}/reset-password`,
      });
      if (error) {
        return toast.error("Could not update password");
      }
      return toast.success("Email for Reset Password sent sucessfully");
    } catch (err) {
      return toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetAPI = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_NOBOUNCE_WEBHOOK_URL}/reset-api-key`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${process.env.REACT_APP_NOBOUNCE_AUTHORIZATION}`,
          },
          body: JSON.stringify({
            "current api key": user?.uniqueAPI,
            "unique user id": user?.uniqueUserID,
            "user email": user?.email,
          }),
        }
      );
      const data = await res.json();
      setUser({ ...user, uniqueAPI: data["new-api-key"] });
      return toast.success("API Key Reset Successfully");
    } catch (err) {
      return toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleApiDoc = async () => {
    setLoading(true);
    window.open("https://app.theneo.io/magicallygenius/verifymagically", '_blank');
    setLoading(false);
  };

  return (
    <Flex horizontal="true" gap={40}>
      <Card
        title="Account Details"
        className="w-[450px] max-w-[450px] min-w-[350px]"
      >
        <div className="flex gap-4 flex-col w-full">
          <span>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              placeholder="Email"
              id="email"
              value={user?.email}
              readOnly
            />
          </span>
          <Button
            type="primary"
            className="w-48"
            danger
            onClick={handleUpdatePassword}
            loading={loading}
          >
            Update Password
          </Button>
        </div>
      </Card>
      <Card
        title="User API Key"
        className="w-[450px] max-w-[450px] min-w-[350px]"
      >
        <div className="flex gap-4 flex-col w-full">
          <span>
            <label htmlFor="apiKey">Current User API Key</label>
            <span className="flex items-center gap-2">
              <Input value={user?.uniqueAPI} readOnly id="apiKey" />
              <button
                className="text-gray-600 text-lg"
                onClick={() => {
                  navigator.clipboard.writeText(user?.uniqueAPI);
                  toast.success("API Key Copied");
                }}
              >
                <CopyOutlined />
              </button>
            </span>
          </span>
          <span className="flex justify-between	">
          <Button
            type="primary"
            className="w-48"
            danger
            onClick={handleResetAPI}
            loading={isLoading}
          >
            Refresh Unique API
          </Button>
          <Button
            type="primary"
            className="w-48"
            primary
            onClick={handleApiDoc}
            loading={isLoading}
          >
            API Documentation
          </Button>
          </span>
        </div>
      </Card>
    </Flex>
  );
}

export default Profile;
