import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Button, theme, Flex, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  MenuFoldOutlined,
  CalendarOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";
import Home from "../../pages/Home";
import UploadSection from "../../pages/Upload";
import Logs from "../../pages/Logs";
import Billing from "../../pages/Billing";
import Profile from "../../pages/Profile";
import logo from "../../assets/images/verifymagically.png";
import { supabase } from "../supaClient";
import { FileContext } from "../../context/fileContext";
import { PoweroffOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const { Header, Content, Sider } = Layout;

function Main({ children }) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [user, setUser] = useState({});
  const [activeTab, setActiveTab] = useState("dashboard");
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [fileData, setFileData] = useState([]);
  const [fileName, setFileName] = useState("No file selected");
  const [acceptedFile, setAcceptedFile] = useState(null);

  useEffect(() => {
    if (acceptedFile && acceptedFile.size >= 72351744) {
      setFileData([]);
      setFileName("No file selected");
      return toast.error("Please contact support team for bigger file uploads");
    }
  }, [acceptedFile]);

  const handleLogout = (e) => {
    e.preventDefault();
    supabase.auth.signOut();
    navigate("/login");
  };

  // useEffect to set activeTab based on the pathname
  useEffect(() => {
    // Extract pathname from location
    const { pathname } = location;
    // Set active tab based on pathname
    if (pathname.includes("/profile")) {
      setActiveTab("profile");
    } else if (pathname.includes("/upload")) {
      setActiveTab("upload");
    } else if (pathname.includes("/logs")) {
      setActiveTab("logs");
    } else if (pathname.includes("/billing")) {
      setActiveTab("billing");
    } else {
      setActiveTab("dashboard");
    }
  }, [location]);

  function renderContent() {
    switch (activeTab) {
      case "dashboard":
        return <Home />;
      case "upload":
        return <UploadSection />;
      case "logs":
        return <Logs />;
      case "billing":
        return <Billing />;
      case "profile":
        return <Profile />;
      default:
        return <Home />;
    }
  }

  const menuItems = [
    {
      key: "dashboard",
      icon: <AppstoreOutlined />,
      label: "Dashboard",
      path: "/",
    },
    {
      key: "logs",
      icon: <CalendarOutlined />,
      label: "Logs",
      path: "/logs",
    },
    {
      key: "billing",
      icon: <AccountBookOutlined />,
      label: "Billing",
      path: "/billing",
    },
  ];
  return (
    <Layout className="h-screen">
      <FileContext.Provider
        value={{
          fileData,
          setFileData,
          fileName,
          setFileName,
          acceptedFile,
          setAcceptedFile,
          user,
          setUser,
        }}
      >
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="py-4 px-4 text-white text-xs font-semibold">
            {collapsed ? (
              <img src={logo} alt="Logo" className="inline px-1 h-full w-10" />
            ) : (
              <a href="/" className="hover:text-[#fff]">
                <img
                  src={logo}
                  alt="Logo"
                  className="inline px-1 h-full w-10"
                />
                Verify Magically
              </a>
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[activeTab]}
            items={menuItems.map((item) => ({
              key: item.key,
              icon: item.icon,
              label: item.label,
              onClick: () => navigate(item.path),
            }))}
          />
          <Button
            type="primary"
            className={`w-3/4 ${
              collapsed ? "mx-2" : "mx-4"
            } mt-5 absolute bottom-10 h-[50px] flex items-center justify-center`}
            danger
            onClick={handleLogout}
          >
            {collapsed ? (
              <PoweroffOutlined />
            ) : (
              <>
                <PoweroffOutlined /> Logout
              </>
            )}
          </Button>
        </Sider>
        <Layout className="overflow-y-scroll">
          <Header
            style={{
              padding: "0px 50px 0px 10px",
              background: colorBgContainer,
            }}
          >
            <Flex align="center" justify="space-between" horizontal="true">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                name={"searchBar"}
                style={{
                  width: 64,
                  height: 64,
                  boxShadow: "none",
                }}
              />
              <div>
                <Link to="/billing">
                  <Button className="bg-green-600 text-white mr-4 rounded-lg font-normal pointer-events-none cursor-none">
                    Credits: {user.credits}
                  </Button>
                </Link>
                <Link
                  to="/profile"
                  className="text-xl cursor-pointer hover:text-blue-500"
                >
                  <UserOutlined />
                </Link>
              </div>
            </Flex>
          </Header>
          <Content
            className="p-6 min-h-[280px] h-full"
            style={{
              borderRadius: borderRadiusLG,
            }}
          >
            {renderContent(activeTab)}
          </Content>
        </Layout>
      </FileContext.Provider>
    </Layout>
  );
}

export default Main;
