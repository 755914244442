import React, { useContext, useState } from "react";
import { Switch } from "antd";
import { CheckCircleFilled, ContainerOutlined,ApartmentOutlined } from "@ant-design/icons";
import { FileContext } from "../context/fileContext";
import { toast } from "react-toastify";
import { supabase } from "../components/supaClient";

export default function Billing() {
  const [isLoading, setIsLoading] = useState(false);
  const [isUSD, setIsUSD] = useState(true);
  const [selectedValue, setSelectedValue] = useState("10000");
  const { user } = useContext(FileContext);


  const credits = { 
    10000: {
      credit: "10k",
      amountUSD: 36.0,
      amountINR: 3525.84,
    },
    25000: {
      credit: "25k",
      amountUSD: 48.0,
      amountINR: 4701.12,
    },
    50000: {
      credit: "50k",
      amountUSD: 76,
      amountINR: 7443.44,
    },
    100000: {
      credit: "100k",
      amountUSD: 129.0,
      amountINR: 12634.26,
    },
    500000: {
      credit: "500k",
      amountUSD: 349.0,
      amountINR: 34180.0,
    },
    1000000: {
      credit: "1M",
      amountUSD: 555.0,
      amountINR: 54355.0,
    },
    5000000: {
      credit: "5M",
      amountUSD: 1999.0,
      amountINR: 195880.0,
    },
    10000000: {
      credit: "10M",
      amountUSD: 3499.0,
      amountINR: 342690.0,
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      const body = {
        numberOfLeads: selectedValue,
        userId: user?.stripeID,
        userEmail: session?.user?.email,
        currency: isUSD ? "usd" : "inr",
      };
      const response = await fetch(process.env.REACT_APP_STRIPE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status !== 200) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      window.location.href = data.url;
    } catch (err) {
      return toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };




  return (
    <div className="w-full flex flex-col items-center">
      <form
        className="w-2/3 flex justify-center items-center flex-col lg:flex-row mt-10"
        onSubmit={handleSubmit}
      >
        <div className="w-full text-black grid lg:grid-cols-3 shadow-xl rounded-lg border p-10 gap-5">
          <div className="col-span-3 text-center text-2xl font-bold">
            Select your order to continue
          </div>
          {Object.keys(credits)?.map((value, index) => (
            <span
              className={`${
                selectedValue === value
                  ? "text-white bg-[#2f1e56]"
                  : "border-gray-200  hover:bg-gray-200"
              } py-4 flex flex-col items-center justify-center border-2 text-md xl:text-2xl rounded-xl cursor-pointer transition-all`}
              onClick={() => setSelectedValue(value)}
              key={index}
            >
              {credits[value]?.credit}
              <span className="text-xs xl:text-sm">Credits</span>
            </span>
          ))}
          <div className="col-span-3 text-lg text-center">
            Need an Invoice.{" "}
            <a
              href={`https://zenvoice.io/p/662102288eebcfdd56227f5e?email=${user?.email}`}
              className="text-[#6c4cff] hover:text-[#6c4cff] hover:underline"
            >
              Get it from here! <ContainerOutlined />
            </a>
          </div>
          <div className="col-span-3 text-lg text-center">
            Want to earn some extra money?{" "}
            <a
              href={`https://affiliate.verifymagically.com`}
              target="_blank"
              className="text-[#6c4cff] hover:text-[#6c4cff] hover:underline"
            >
              Become An Affiliate! <ApartmentOutlined />
            </a>
          </div>
        </div>
        <div className="min-w-[250px] xl:min-w-[400px] w-1/3 bg-gradient-to-br from-[#1a112f] to-[#2f1e56] p-10 gap-5 text-white flex flex-col items-center justify-center lg:rounded-xl">
          <div className="text-2xl xl:text-4xl font-bold tracking-wider">
            {`${
              isUSD
                ? `$${credits[selectedValue]?.amountUSD}`
                : `₹${credits[selectedValue]?.amountINR}`
            }`}
          </div>
          <div className="grid grid-cols-2">
            <span className="flex flex-col items-center justify-center text-lg xl:text-3xl font-bold tracking-wide border-r-2 border-gray-200 pr-4">
              {selectedValue}
              <span className="text-xs xl:text-base font-normal">Credits</span>
            </span>
            <span className="flex flex-col items-center justify-center text-lg xl:text-3xl font-bold tracking-wide px-10 text-green-400">
              {isUSD
                ? (selectedValue / credits[selectedValue]?.amountUSD).toFixed(2)
                : (selectedValue / credits[selectedValue]?.amountINR).toFixed(
                    2
                  )}
              <span className="text-xs xl:text-base font-normal">
                {`Emails/${isUSD ? "USD" : "INR"}`}
              </span>
            </span>
          </div>
          <div className="font-bold flex items-center gap-4">
            INR
            <Switch
              value={isUSD}
              onChange={() => setIsUSD(!isUSD)}
              className="bg-green-500"
            />
            USD
          </div>
          <button
            className="text-base xl:text-lg font-bold bg-[#6c4cff] hover:bg-[#4a36a1] text-white w-full text-center py-3 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Pay now"}
          </button>
          {/* <p>Any referral code?</p>
          <input className="w-full rounded-md pl-5" placeholder="Enter referral code">
          
          </input> */}
          <ul className="w-full">
            <li className="text-sm xl:text-lg py-1">
              <span className="text-green-700 pr-2">
                <CheckCircleFilled />
              </span>
              99% Accuracy
            </li>
            <li className="text-sm xl:text-lg py-1">
              <span className="text-green-700 pr-2">
                <CheckCircleFilled />
              </span>
              Realtime Catch All Validation
            </li>
            <li className="text-sm xl:text-lg py-1">
              <span className="text-green-700 pr-2">
                <CheckCircleFilled />
              </span>
              Bulk Verification
            </li>

            <li className="text-sm xl:text-lg py-1">
              <span className="text-green-700 pr-2">
                <CheckCircleFilled />
              </span>
              API Integration and Priority Support
            </li>
            {selectedValue !== "5000" && (
              <li className="text-sm xl:text-lg py-1">
                <span className="text-green-700 pr-2">
                  <CheckCircleFilled />
                </span>
                Founders best wishes
              </li>
            )}
          </ul>
        </div>
      </form>
    </div>
  );
}
