import { Table, Tag, Flex, Input } from "antd";
import React, { useEffect, useState } from "react";
import { supabase } from "../components/supaClient";
import { toast } from "react-toastify";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

const Logs = () => {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "date",
      render: (text) => text.split("T")[0],
    },
    {
      title: "File Name",
      dataIndex: "file name",
      key: "fileName",
      width: "60%",
      render: (text) => <p className="truncate max-w-[600px]">{text}</p>,
    },
    {
      title: "Credit Type",
      dataIndex: "credit type",
      key: "creditType",
      render: (text) => {
        if (text === "deducted") {
          return <Tag color={"red"}>{text.toUpperCase()}</Tag>;
        }
        if (text === "refunded") {
          return <Tag color={"orange"}>{text.toUpperCase()}</Tag>;
        }
        if (text === "added") {
          return <Tag color={"green"}>{text.toUpperCase()}</Tag>;
        }
      },
    },
    {
      title: "Credits",
      dataIndex: "number of credits",
      key: "credits",
    },
  ];

  useEffect(() => {
    (async () => {
      if (searchInput === "") {
        const { data: logs, error } = await supabase
          .from("user-credit-logs")
          .select("*")
          .order("created_at", { ascending: false });

        setData(logs);
        if (error) {
          return toast.error("Could not fetch Data");
        }
      } else {
        const { data: logs, error } = await supabase
          .from("user-credit-logs")
          .select("*")
          .order("created_at", { ascending: false })
          .ilike("file name", `%${searchInput}%`);
        setData(logs);
        if (error) {
          return toast.error("Could not fetch Data");
        }
      }
    })();
  }, [searchInput]);

  return (
    <div>
      <Flex
        gap="middle"
        justify={"space-between"}
        horizontal="true"
        className="my-5"
      >
        <Input
          className="w-[300px] h-[50px]"
          placeholder="Type here..."
          onChange={(e) => setSearchInput(e.target.value)}
          prefix={<SearchOutlined />}
        />
        <CSVLink
          filename={"Logs"}
          data={[
            ["Date", "id", "File Name", "Credit Type", "Credits"],
            ...data.map((item) => {
              return [
                item.created_at,
                item.id,
                item["file name"],
                item["credit type"],
                item["number of credits"],
              ];
            }),
          ]}
          className="bg-[#1677ff] text-white hover:text-white px-4 py-2 rounded-md flex items-center hover:bg-[#1957ae]"
        >
          Export CSV
        </CSVLink>
      </Flex>
      <Table columns={columns} dataSource={data} />
      <br />
    </div>
  );
};

export default Logs;
