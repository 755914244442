import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Typography, Form, Input } from "antd";
import { supabase } from "../components/supaClient";
import logo from "../assets/images/verifymagically.png";
import { toast } from "react-toastify";

const { Title } = Typography;
const { Header, Content } = Layout;

export default function ResetPassword() {
  const navigate = useNavigate();

  const updatePassword = async (values) => {
    const { password, confirmPassword } = values;

    if (password !== confirmPassword) {
      return toast.error("Passwords do not match");
    }

    const { error } = await supabase.auth.updateUser({
      password,
    });

    if (error) {
      toast.error("Could not Authenticate User");
      return;
    }
    toast.success("Password Updated Successfully");
    setTimeout(() => {
      return navigate("/");
    }, 3000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    toast.error("Failed to sign in");
  };

  return (
    <>
      <Layout className="">
        <Header>
          <span className="flex justify-start items-center font-semibold text-white">
            <img src={logo} width="50px" alt={"Magically Genius"} />
            Magically Genius
          </span>
        </Header>
        <Content className="h-screen flex items-start justify-center pt-36">
          <div className="max-w-[450px]">
            <Title className="mb-15">Update Password</Title>
            <Title className="font-regular text-muted" level={5}>
              Enter New password to reset your password
            </Title>
            <Form
              onFinish={updatePassword}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="w-full"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" autoComplete="false" />
              </Form.Item>
              <Form.Item
                className="w-full"
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" autoComplete="false" />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="text-white border-none bg-red-700 w-full h-[40px] font-medium uppercase"
                >
                  Update Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    </>
  );
}
